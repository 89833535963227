import Images from "~data/imageImports";

const ProcessData = [
      {
        id:"sp1",
        icon: "fas fa-cogs",
        title: "Kodawari",
        text:
        "Búsqueda inflexible de la perfección",
      },
      {
        id:"sp2",
        icon: "fas fa-chart-bar",
        title: "Kaizen",
        text:
        'Mejora continua laboral y personal',
      },
      {
        id:"sp3",
        icon: "fas fa-child",
        title: "Horenso",
        text:
          "Reportes frecuentes, contacto y discusión",
      },
  ]
  
  
  export default ProcessData;