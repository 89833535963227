import Images from "~data/imageImports";
const BlogData = [
      {
        id:"bg1",
        image: "https://res.cloudinary.com/dv10simqm/image/upload/v1642031386/helios/Desarrollo%20de%20Software/blog1_2x_o51nxl.png",
        badge:"Gadgets",
        date:"01 June, 2020",
        title: "We can blend colors multiple<br class='d-none d-xs-block'> ways, the most common",
        user:"By George Lee",
        commentCount:" 1"
      },
      {
        id:"bg2",
        image: "https://res.cloudinary.com/dv10simqm/image/upload/v1642031386/helios/Desarrollo%20de%20Software/blog2_2x_v3jt4e.png",
        badge:"Gadgets",
        date:"01 June, 2020",
        title: "We can blend colors multiple<br class='d-none d-xs-block'> ways, the most common",
        user:"By George Lee",
        commentCount:" 2"
      },
      {
        id:"bg3",
        image: "https://res.cloudinary.com/dv10simqm/image/upload/v1642031386/helios/Desarrollo%20de%20Software/blog3_2x_ug9fur.png",
        badge:"Gadgets",
        date:"01 June, 2020",
        title: "We can blend colors multiple<br class='d-none d-xs-block'> ways, the most common",
        user:"By George Lee",
        commentCount:" 3"
      },

  ]
  export default BlogData;